import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { BlogPost } from 'ui/screens';

/**
 * Single post view (/blog/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const BlogPostPage = ({ data, location }) => {
  return <BlogPost post={data.ghostPost} location={location}></BlogPost>;
};

BlogPostPage.propTypes = {
  data: PropTypes.shape({
    ghostPost: PropTypes.shape({
      codeinjection_styles: PropTypes.object,
      title: PropTypes.string.isRequired,
      excerpt: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      feature_image: PropTypes.string,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
};

BlogPostPage.defaultProps = {};

export default BlogPostPage;

export const BlogPostQuery = graphql`
  query ($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      id
      title
      slug
      excerpt
      published_at
      html
      feature_image
      visibility
      tags {
        id
        name
        slug
      }
      primary_tag {
        id
        slug
      }
      authors {
        id
        slug
      }
    }
  }
`;
